* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}

html {
   --section-background-color: linear-gradient(to bottom left, rgba(17, 16, 16, 0.582), rgba(12, 8, 24, 0.904));
   --image-gradient: linear-gradient(to bottom right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5));
   --primary-gradient: linear-gradient(to bottom right, rgba(242, 149, 68, 0.8), rgba(242, 149, 68, 0.9));
   --imp-text-color: #f29544;
   --primary-color: #f29544;
   --primary-color-dark: #a64e46;
   --secondary-color: #2e1a0f;
   --secondary-color-dark: #1e0d07;
   --imp-secondary-color: rgba(12, 10, 22, 0.863);
   font-size: 62.5%;
}

body {
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   display: block;
}

::selection {
   background: var(--imp-text-color);
   color: rgba(12, 10, 22, 0.863);
}

::-webkit-scrollbar {
   width: 4px;
}

::-webkit-scrollbar-track {
   background: var(--secondary-color);
}

::-webkit-scrollbar-thumb {
   background: var(--primary-color);
   border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
   background: var(--primary-color-dark);
   border-radius: 5px;
}

.progress-indicator.available {
   opacity: 0;
}

.progress-indicator {
   background-color: var(--secondary-color);
   width: 100%;
   height: 100%;
   position: fixed;
   opacity: 1;
   pointer-events: none;
   -webkit-transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 436ms;
   -moz-transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 436ms;
   transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 436ms;
   z-index: 9999;
}

.insp-logo-frame {
   display: -webkit-flex;
   display: -moz-flex;
   display: flex;
   -webkit-flex-direction: column;
   -moz-flex-direction: column;
   flex-direction: column;
   -webkit-justify-content: center;
   -moz-justify-content: center;
   justify-content: center;
   -webkit-animation: fadein 436ms;
   -moz-animation: fadein 436ms;
   animation: fadein 436ms;
   height: 98%;
}

.insp-logo-frame-img {
   width: 200px;
   height: 200px;
   -webkit-align-self: center;
   -moz-align-self: center;
   align-self: center;
   border-radius: 50%;
}

.insp-logo-frame > h2 {
   font-family: "ClashGrotesk";
   color: #f7f7f7;
   font-weight: 500;
   line-height: 1.4;
   font-size: 1.6rem;
   text-align: center;
   width: 40%;
   margin-inline: auto;
}

.progress-indicator-head {
   background-color: #15151c;
   height: 5px;
   overflow: hidden;
   position: relative;
}

.progress-indicator .first-indicator,
.progress-indicator .second-indicator {
   background-color: var(--imp-text-color);
   bottom: 0;
   left: 0;
   right: 0;
   top: 0;
   position: absolute;
   -webkit-transform-origin: left center;
   -moz-transform-origin: left center;
   transform-origin: left center;
   -webkit-transform: scaleX(0);
   -moz-transform: scaleX(0);
   transform: scaleX(0);
}

.progress-indicator .first-indicator {
   -webkit-animation: first-indicator 2s linear infinite;
   -moz-animation: first-indicator 2s linear infinite;
   animation: first-indicator 2s linear infinite;
}

.progress-indicator .second-indicator {
   -webkit-animation: second-indicator 2s linear infinite;
   -moz-animation: second-indicator 2s linear infinite;
   animation: second-indicator 2s linear infinite;
}

.progress-indicator .insp-logo {
   animation: App-logo-spin infinite 20s linear;
   border-radius: 50%;
   -webkit-align-self: center;
   -moz-align-self: center;
   align-self: center;
}

@-webkit-keyframes fadein {
   from {
      opacity: 0;
   }

   to {
      opacity: 1;
   }
}

@-moz-keyframes fadein {
   from {
      opacity: 0;
   }

   to {
      opacity: 1;
   }
}

@keyframes fadein {
   from {
      opacity: 0;
   }

   to {
      opacity: 1;
   }
}

@keyframes first-indicator {
   0% {
      transform: translate(0) scaleX(0);
   }

   25% {
      transform: translate(0) scaleX(0.5);
   }

   50% {
      transform: translate(25%) scaleX(0.75);
   }

   75% {
      transform: translate(100%) scaleX(0);
   }

   100% {
      transform: translate(100%) scaleX(0);
   }
}

@keyframes second-indicator {
   0% {
      transform: translate(0) scaleX(0);
   }

   60% {
      transform: translate(0) scaleX(0);
   }

   80% {
      transform: translate(0) scaleX(0.6);
   }

   100% {
      transform: translate(100%) scaleX(0.1);
   }
}

@media only screen and (max-width: 720px) {
   .insp-logo-frame > h2 {
      font-size: 1.4rem;
   }
}
