@import 'animate.css';

@font-face {
   font-family: 'La Belle Aurore';
   src: url('./assets/fonts/LaBelleAurore.woff2') format('woff2');
}

@font-face {
   font-family: 'Coolvetica';
   src: url('./assets/fonts/CoolveticaRg-Regular.woff2') format('woff2');
}


@font-face {
  font-family: 'ClashGrotesk';
  src: url('./assets/fonts/ClashGrotesk-Variable.woff2') format('woff2'),
       url('./assets/fonts/ClashGrotesk-Variable.woff') format('woff');
       font-weight: 200 700;
       font-display: swap;
       font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=La+Belle+Aurore&display=swap');

input,
textarea {
   font-family: 'Helvetica Neue';
}

.App {
   width: 100vw;
   height: 100vh;
   display: block;
   overflow: hidden;
   background-image: var(--image-gradient), url(./assets/images/home-bg-new.jpg);
   background-position: top;
   background-size: cover;
   font-family: "ClashGrotesk", sans-serif;
}

.sub-heading {
   text-transform: uppercase;
   font-size: 4.5rem;
   width: 100%;
   text-align: center;
   color: #fff;
   margin-bottom: 3rem;
   display: inline-block;
   animation-duration: 1s;
   animation-fill-mode: both;
   animation-iteration-count: 1;

   span {
      margin-inline: 0.5rem;
      display: inline-block;
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-iteration-count: 1;
      color: var(--imp-text-color);

      &:hover {
         animation-name: swing;
      }
   }
}

.float-button {
   position: fixed;
   bottom: 3rem;
   right: 2rem;
   background-color: var(--imp-text-color);
   border-radius: 50%;
   padding: 0.5rem 1.2rem;
   color: #222;
   text-decoration: none;
   font-size: 1.8rem;

   @media screen and (min-width: 1024px) {
      display: none;
   }
}
