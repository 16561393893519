.hero-text {
   color: #fff;
   font-size: 7rem;
   line-height: 8.5rem;
   font-family: 'ClashGrotesk', sans-serif;
   font-weight: 600;

   @media screen and (min-width: 1920px) {
      font-size: 12.5rem;
      line-height: 12.5rem;
   }

   @media screen and (max-width: 1440px) {
      font-size: 6rem;
      line-height: 6rem;
   }

   @media screen and (max-width: 1024px) {
      font-size: 5rem;
      line-height: 5rem;
      letter-spacing: 1px;
      margin: 0.5rem;
   }

   @media screen and (max-width: 768px) {
      font-size: 5.2rem;
      line-height: 4rem;
      letter-spacing: 2px;
      line-height: 7rem;
   }

   @media screen and (max-width: 425px) {
      font-size: 3.6rem;
      line-height: 3.6rem;
      letter-spacing: 1.5px;
      line-height: 4.5rem;
   }

   &:before {
      content: '<h1> ';
      font-family: 'La Belle Aurore', cursive;
      color: var(--imp-text-color);
      font-weight: 400;
      font-size: 2rem;
      position: absolute;
      margin-top: -6rem;
      left: -3rem;
      opacity: 0.6;

      @media screen and (max-width: 1024px) {
         font-size: 1.8rem;
         margin-top: -4rem;
         left: -1.5rem;
      }

      @media screen and (max-width: 425px) {
         font-size: 1.6rem;
         position: absolute;
         margin-top: -3.5rem;
         left: -1.5rem;
      }
   }

   &:after {
      content: ' <h1/>';
      font-family: 'La Belle Aurore', cursive;
      color: var(--imp-text-color);
      font-weight: 400;
      font-size: 2rem;
      position: absolute;
      margin-top: 20px;
      margin-left: 22px;
      animation: fadeIn 1s 1.7s backwards;
      opacity: 0.6;

      @media screen and (max-width: 1024px) {
         font-size: 1.8rem;
         position: absolute;
         margin-top: 1.8rem;
         margin-left: 2rem;
      }

      @media screen and (max-width: 425px) {
         font-size: 1.6rem;
         position: absolute;
         margin-top: 1.8rem;
         margin-left: 1.3rem;
      }
   }
}

.text-animate {
   display: inline-block;
   opacity: 0;
   animation: bounceIn;
   animation-duration: 1s;
   animation-delay: 1s;
   animation-fill-mode: forwards;
   min-width: 10px;
}

.text-animate-hover {
   min-width: 10px;
   display: inline-block;
   animation-fill-mode: both;

   &:hover {
      animation: rubberBand 1s;
      color: var(--imp-text-color);
   }
}

@for $i from 1 through 35 {
   .text-animate._#{$i} {
      animation-delay: #{$i / 12}s;
   }
}
