.nav-bar {
   background: var(--secondary-color);
   width: 6.5rem;
   height: 100vh;
   position: absolute;
   top: 0;
   z-index: 1001;
   padding: 1.5rem 0rem;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   

   @media screen and (max-width: 1024px) {
      display: none;
   }

   .logo {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
         width: 3.5rem;
         height: auto;
      }
   }

   nav {
      display: block;
      text-align: center;
      position: absolute;
      height: 50%;
      top: 35%;
      transform: translate(0, -25%);
      width: 100%;

      a {
         font-size: 2.2rem;
         color: #fff;
         display: block;
         line-height: 5.5rem;
         height: 5.5rem;
         position: relative;
         text-decoration: none;

         i {
            transition: all 0.3s ease-out;
         }

         &:hover {
            color: var(--imp-text-color);

            svg {
               opacity: 0;
            }

            &:after {
               opacity: 1;
            }
         }

         &:after {
            content: '';
            font-size: 0.9rem;
            letter-spacing: 2px;
            position: absolute;
            bottom: 0;
            display: block;
            width: 100%;
            text-align: center;
            opacity: 0;
            -webkit-transition: all 0.3s ease-out;
            transition: all 0.3s ease-out;
         }

         &:first-child {
            &:after {
               content: 'HOME';
            }
         }
      }

      a.about-link {
         &:after {
            content: 'ABOUT';
         }
      }

      a.skills {
         &:after {
            content: 'SKILLS';
         }
      }

      a.projects {
         &:after {
            content: 'PROJECTS';
         }
      }
      a.resume{
         &:after {
            content: 'RESUME';
         }
      }

      a.contact-link {
         &:after {
            content: 'CONTACT';
         }
      }
      

      a.active {
         svg {
            color: var(--imp-text-color);
         }
      }
   }

   ul {
      position: absolute;
      bottom: 2rem;
      width: 100%;
      display: block;
      padding: 0;
      list-style: none;
      text-align: center;
      margin: 0;

      li {
         a {
            padding: 0.7rem 0;
            display: block;
            font-size: 1.5rem;
            line-height: 1.6rem;
            color: #fff;

            &:hover {
               color: var(--imp-text-color);
            }
         }
      }

      .link {
         &:hover {
            svg {
               color: var(--imp-text-color);
            }
         }
      }
   }
}
