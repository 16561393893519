.loader-page {
   width: 100%;
   height: 100%;
   position: relative;
   background-image: var(--image-gradient);
   z-index: 99;
   .loader-container {
      z-index: 9999;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @media screen and (max-width: 1024px) {
         top: 35%;
         left: 50%;
      }
      
     & > .loader{
         color: var(--imp-text-color);
      }
   }

}
