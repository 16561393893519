.resume-page {
   width: 100%;
   height: 100%;
   color: #fff;
   overflow-y: auto;
   display: flex;
   flex-direction: column;

   @media screen and (max-width: 1024px) {
      align-items: center;
      // padding-bottom: 6rem;
   }

   .resume-download-button {
      z-index: 1;
      margin: 0 auto;
      font-family: 'Lato';
      color: #fff;
      border: 1.5px solid var(--imp-text-color);
      background: var(--imp-text-color);

      font-size: 1.4rem;
      letter-spacing: 3px;
      text-decoration: none;
      padding: 0.8rem 1.5rem;
      border-radius: 2rem;
      float: right;
      animation: fadeInAnimation 1s 1.8s backwards;
      transition: hover 0.2s ease-in-out;
      cursor: pointer;

      a {
         color: #fff;
         text-decoration: none;
      }

      &:hover {
         background: var(--imp-text-color)
      }
   }

   .resume-title {
      margin-top: 2rem;
      margin-bottom: 3rem;
      padding: 0;

      span {
         color: #fff;
      }
   }

   .resume-container {
      padding-top: 50px;
      // padding-bottom: 50px;
      justify-content: center;
   }

   .document {
      display: flex;
      justify-content: center;
   }
}
