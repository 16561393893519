.navbar-mobile {
   background-image: linear-gradient(to left, var(--secondary-color), var(--secondary-color));
   position: relative;
   top: 0;
   left: 0;
   height: 6.5rem;
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 0.3rem 1rem;

   @media screen and (min-width: 1024px) {
      display: none;
   }

   .bg {
      position: absolute;
      right: 1em;
      width: 4.2em;
      height: 4.2em;
      border-radius: 0.5rem;
      background-image: radial-gradient(var(--primary-color), var(--primary-color-dark));
      z-index: 2000;
      transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
   }

    .logo {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
         width: 4rem;
         height: auto;
      }
   }
   .checkbox {
      display: none;
   }

   .btn {
      position: absolute;
      right: 1rem;
      width: 4.2rem;
      height: 4.2rem;
      border-radius: 0.5rem;
      background-color: var(--imp-text-color);
      z-index: 3000;
      text-align: center;
      cursor: pointer;
      box-shadow: 0rem 1rem 4rem rgba(#000, 0.2);
   }

   .nav {
      height: 100vh;
      width: 0%;
      visibility: hidden;
      
      opacity: 0;
      z-index: 4000;
      position: fixed;
      top: 5%;
      left: 0%;
      transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
   }

   .list {
      width: 100%;
      list-style: none;
      text-align: center;
      position: absolute;
      top: 40%;
      left: 45%;
      transform: translate(-50%, -50%);
   }

   .item {
      margin: 1.5rem;
   }

   .link {
      &:link,
      &:visited {
         display: inline-block;
         padding: 1rem 2rem;
         font-size: 2.8rem;
         font-weight: 300;
         color: #fff;
         text-decoration: none;
         text-transform: uppercase;
         background-image: linear-gradient(120deg, transparent 50%, #fff 50%);
         background-size: 220%;
         transition: all 0.4s ease;
      }

      &:hover,
      &:active {
         background-position: 100%;
         color: var(--imp-text-color);
         transform: translateX(1rem);
      }

      span {
         margin-right: 1.5rem;
         display: inline-block;
      }
   }

   .active {
      background-position: 100%;
      color: var(--imp-text-color) !important;
      transform: translateX(1rem);
   }

   .checkbox:checked ~ .bg {
      transform: scale(80);
   }
   .checkbox:checked ~ .nav {
      opacity: 1;
      width: 100vw;
      visibility: visible;
   }

   .icon {
      margin-top: 2em;
      position: relative;
      &,
      &::after,
      &::before {
         width: 2.5rem;
         height: 2.2px;
         background-color: var(--secondary-color);
         display: inline-block;
      }

      &::after,
      &::before {
         content: '';
         position: absolute;
         left: 0;
         transition: all 0.2s;
      }

      &::before {
         top: -0.8rem;
      }

      &::after {
         top: 0.8rem;
      }
   }

   .btn:hover .icon::before {
      top: -0.8rem;
   }

   .btn:hover .icon::after {
      top: 0.8rem;
   }

   .checkbox:checked + .btn .icon {
      background-color: transparent;
   }

   .checkbox:checked + .btn .icon::before {
      top: 0;
      transform: rotate(135deg);
   }
   .checkbox:checked + .btn .icon::after {
      top: 0;
      transform: rotate(-135deg);
   }
}
