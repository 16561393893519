.contact-page {
   width: 100%;
   height: 100%;
   color: #fff;
   display: flex;
   overflow-y: auto;

   @media screen and (max-width: 1024px) {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: flex-start;
      padding-bottom: 6rem;
   }

   .contact-title {
      @media screen and (max-width: 1024px) {
         margin-top: 0rem;
         padding-top: 0rem;
         font-size: 3.5rem;
      }
   }

   .text-zone {
      width: 75%;
      margin-left: 5rem;
      margin-top: 4rem;
      padding: 2rem 4rem;

      p {
         font-size: 2rem;

         @media screen and (max-width: 1024px) {
            font-size: 1.6rem;
         }

         span {
            color: var(--imp-text-color);
            margin-inline: 0.5rem;
            display: inline-block;
            animation-duration: 1s;
            animation-fill-mode: both;
            animation-iteration-count: 1;
            &:hover {
               animation-name: swing;
            }
         }
      }

      @media screen and (max-width: 1024px) {
         width: 100%;
         margin-left: 0;
         margin-top: 2rem;
         padding: 2rem 2rem;
      }
   }

   .contact-form {
      margin-block: 4rem;
      width: 100%;

      ul {
         padding: 0;
         margin: 0;

         li {
            padding: 0;
            margin: 0;
            list-style: none;
            margin-bottom: 1rem;
            opacity: 0;
            overflow: hidden;
            display: block;
            clear: both;
            position: relative;
            animation: fadeInUp 2s 0.1s;
            animation-fill-mode: forwards;
         }

         li.half {
            width: 49%;
            margin-left: 2%;
            float: left;
            clear: none;

            &:first-child {
               margin-left: 0;
            }
         }
      }

      input[type='text'],
      input[type='email'] {
         font-family: 'Lato';
         width: 100%;
         border: 0;
         background-image: var(--primary-gradient) ;
         height: 5rem;
         font-size: 1.8rem;
         color: var(--imp-secondary-color);
         padding: 0 2rem;
         box-sizing: border-box;
         border-radius: 4px;

         &:focus {
            outline: none;
            border: 2px solid var(--image-gradient);
         }

         &::placeholder {
            color: #fff;
         }
      }

      textarea {
         width: 100%;
         font-family: 'Lato';
         border: 0;
         background-image: var(--primary-gradient) ;
         color: var(--imp-secondary-color);
         border-radius: 4px;
         height: 5rem;
         font-size: 1.8rem;
         padding: 2rem;
         box-sizing: border-box;
         min-height: 15rem;

         &:focus {
            outline: none;
            border: 2px solid var(--image-gradient);
         }

         &::placeholder {
            color: #fff;
         }
      }

      .flat-button {
         font-family: 'Lato';
         color: var(--imp-text-color);
         border: 1.5px solid var(--imp-text-color);
         background: 0 0;
         font-size: 1.4rem;
         letter-spacing: 3px;
         text-decoration: none;
         padding: 0.8rem 1.5rem;
         border-radius: 2rem;
         float: right;
         animation: fadeInAnimation 1s 1.8s backwards;
         transition: hover 0.2s ease-in-out;

         &:hover {
            background: var(--imp-text-color);
            color: #000;
         }
      }
   }

   .map-wrap {
      background: rgba(136, 28, 157, 0.1);
      width: 50%;
      z-index: 1000;

      @media screen and (max-width: 1024px) {
         width: 100%;
         height: auto;
      }

      iframe {
         opacity: 0;
         animation: backInRight 1.8s 0.05s;
         animation-fill-mode: forwards;
         width: 100%;
         height: 100%;
      }
   }
}
