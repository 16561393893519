.projects-page {
   width: 100%;
   height: 100%;
   color: #fff;
   display: block;
   padding-bottom: 10rem;
   padding-top: 4rem;
   overflow-y: auto;
}

.sub-heading{
   letter-spacing: 2px;
}

.project-container {
   width: 100%;
   display: flex;
   flex-wrap: wrap;
   padding-left: 10%;
   padding-right: 10%;
   justify-content: center;
   align-items: center;

   margin: 2rem auto;
   align-items: center;
   gap: 6rem;
   row-gap: 5rem;
   list-style: none;

   .project-item {
      width: 40%;
      max-width: 70rem;
      min-width: 40rem;
      height: 50rem;
      border-radius: 5px;
      box-shadow: 0rem 0.2rem 1.5rem var(--imp-text-color);
      transition: all 0.3s ease-in-out;
      
      @media  (max-width: 425px) {
         width: 70%;
         height: 50rem;
         min-width: none;
         max-width: none;
      }
         
   }

   .project-image {
      height: 55%;
      width: 100%;

      img {
         width: 100%;
         height: 100%;
      }
   }

   .project-info {
      .project-name {
         font-size: 2rem;
         font-weight: bold;
         display: flex;
         align-items: center;
         justify-content: space-between;
         padding: 1rem 1rem;

         .project-title {
            text-transform: uppercase;
            letter-spacing: 2px;
            margin-left: 4px;
         }
      }

      .project-btns {
         .project-btn {
            font-size: 2rem;
            border: none;
            background: none;
            color: var(--imp-text-color);
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            padding: 1rem 1rem;
            border-radius: 0.3rem;
            margin-inline: 0.8rem;
            &:hover {
               background-color: var(--imp-text-color);
               color: #fff;
            }
            
            a{
               text-decoration: none;
               color: inherit;
            }
         }
         
         
      }

      .project-tags {
         width: 100%;
         padding: 0rem 0.4rem;
         display: flex;
         flex-wrap: wrap;
         gap: 0.2rem;

         .project-tag {
            font-size: 1.3rem;
            width: fit-content;
            // font-weight: bold;
            margin-inline: 0.5rem;
            padding: 0.5rem 1rem;
            border-radius: 2rem;
            // background-color: var(--imp-text-color);
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
           
         }
      }
   }

   .project-description {
      padding: 1.5rem 1rem;
      
      p{
            font-size: 1.6rem;
            width: 100%;
            border-top: 1px solid #444;
            padding: 1rem 0;
            text-align: center;
            font-family: 'Lato', sans-serif;
      }
   }
}
