.page {
   width: 100%;
   height: 100%;
   display: flex;

   align-items: center;

   @media (min-width: 1980px) {
      background-size: cover;
   }

   @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      background-size: cover;
   }

   .page-content {
      width: 60%;
      height: 100%;
      display: block;
      position: relative;

      @media screen and (max-width: 768px) {
         width: 100%;
         height: 100%;
      }

      @media screen and (max-width: 425px) {
         width: 100%;
         height: 50vh;
      }

      .tag {
         color: var(--imp-text-color);
         opacity: 0.6;
         position: absolute;
         bottom: 0;
         left: 9rem;
         font-size: 2rem;
         font-family: 'La Belle Aurore', cursive;

         @media screen and (min-width: 1920px) {
            font-size: 3rem;
         }

         @media screen and (max-width: 768px) {
            font-size: 1.4rem;
         }

         &--top {
            bottom: auto;
            top: 3.5rem;

            @media screen and (max-width: 1440px) {
               top: 4rem;
               left: 8rem;
            }

            @media screen and (max-width: 1024px) {
               top: 4rem;
               left: 2rem;
            }

            @media screen and (max-width: 425px) {
               display: none;
            }
         }

         &--bottom {
            margin-bottom: 2rem;

            @media screen and (max-width: 1440px) {
               bottom: 3rem;
               left: 8rem;
            }

            @media screen and (max-width: 1024px) {
               margin-bottom: 10rem;
               left: 2rem;
            }

            @media screen and (max-width: 425px) {
               display: none;
            }
         }
      }

      .hero-text-container {
         width: 90%;
         height: 100%;
         position: absolute;
         opacity: 0;
         margin: 0 auto;
         z-index: 1000;
         transform-style: preserve-3d;
         animation: fadeIn 1s forwards;
         animation-delay: 1s;

         @media screen and (max-width: 768px) {
            width: 100%;
            padding: 1rem;
         }

         @media screen and (max-width: 425px) {
            width: 100%;
            margin-top: 6rem;
         }

         .text-zone {
            width: 100%;
            position: absolute;
            left: 70%;
            top: 50%;
            transform: translate(-50%, -50%);
            @media screen and (max-width: 768px) {
               left: 60%;
               top: 45%;
               transform: translate(-50%, -50%);
            }

            @media screen and (max-width: 425px) {
               left: 60%;
               top: 32%;
               transform: translate(-50%, -50%);
            }
         }

         h2 {
            width: 90%;
            color: #8d8d8d;
            font-family: "ClashGrotesk", sans-serif;
            margin-top: 1rem;
            font-weight: 400;
            font-size: 1.2rem;
            letter-spacing: 3px;
            animation: fadeIn 1s 1.8s backwards;

            @media screen and (max-width: 1024px) {
               font-size: 1rem;
            }

            @media screen and (max-width: 425px) {
               font-size: 0.9rem;
               letter-spacing: 1px;
            }
         }

         .flat-button {
            color: var(--imp-text-color);
            border: 1.5px solid var(--imp-text-color);
            font-size: 1.4rem;
            letter-spacing: 3px;
            text-decoration: none;
            padding: 1rem 1.8rem;
            border-radius: 2rem;
            margin-top: 3.4rem;
            float: left;
            animation: fadeInAnimation 1s 1.8s backwards;
            transition: hover 0.2s ease-in-out;

            &:hover {
               background: var(--imp-text-color);
               color: #000;
            }

            @media screen and (max-width: 1024px) {
               font-size: 1.1rem;
               letter-spacing: 2px;
               padding: 1rem 1.2rem;
            }

            @media screen and (max-width: 425px) {
               font-size: 0.8rem;
               letter-spacing: 1px;
               padding: 1rem 0.8rem;
               margin-top: 2rem;
            }
         }
      }
   }
}


.hero {
   opacity: 0.75;

   .hero-logo {
      height: 75vh;
   }

   @media screen and (max-width: 1440px) {
      .hero-logo {
         height: 65vh;
      }
   }

   @media screen and (max-width: 1024px) {
      .hero-logo {
         height: 30rem;
      }
   }

   @media screen and (max-width: 768px) {
      .hero-logo {
         display: none;
      }
   }

   @media screen and (max-width: 425px) {
      height: 65%;

      .hero-logo {
         display: block;
         height: 35vh;
      }
   }
}

// .container.contact-page,
// .container.about-page {
//    .text-zone {
//       position: absolute;
//       left: 10%;
//       top: 50%;
//       transform: translateY(-50%);
//       width: 35%;
//       vertical-align: middle;
//       display: table-cell;
//       max-height: 90%;

//       h1 {
//          font-size: 53px;
//          font-family: 'Coolvetica';
//          color: var(--imp-text-color);
//          font-weight: 400;
//          margin-top: 0;
//          position: relative;
//          margin-bottom: 40px;
//          left: -10px;

//          &:before {
//             content: '<h1>';
//             font-family: 'La Belle Aurore', cursive;
//             color: var(--imp-text-color);
//             font-size: 18px;
//             position: absolute;
//             margin-top: -10px;
//             left: -10px;
//             opacity: 0.6;
//             line-height: 18px;
//          }

//          &:after {
//             content: '<h1/>';
//             font-family: 'La Belle Aurore', cursive;
//             color: var(--imp-text-color);
//             font-size: 18px;
//             line-height: 18px;
//             position: absolute;
//             left: -30px;
//             bottom: -20px;
//             margin-left: 20px;
//             opacity: 0.6;
//          }
//       }

//       p {
//          font-size: 13px;
//          color: #ffc403;
//          font-family: sans-serif;
//          font-weight: 300;
//          max-width: fit-content;
//          animation: pulse 1s;
//          &:nth-of-type(1) {
//             animation-delay: 1.1s;
//          }
//          &:nth-of-type(2) {
//             animation-delay: 1.2s;
//          }
//          &:nth-of-type(3) {
//             animation-delay: 1.3s;
//          }
//       }
//    }

//    .text-animate-hover {
//       &:hover {
//          color: #fff;
//       }
//    }
// }
