.skills-page {
   width: 100%;
   height: 100%;
   color: #fff;
   display: block;
   overflow-y: auto;
   padding-bottom: 10rem;

   .sub-heading--skills {
      font-size: 4.5rem;
      width: 100%;
      text-align: center;
      color: #fff;
      margin-top: 2.5rem;
      display: inline-block;
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-iteration-count: 1;
      letter-spacing: 2px;

      @media screen and (max-width: 768px) {
         font-size: 4rem;
      }

      span {
         margin-inline: 0.5rem;
         display: inline-block;
         animation-duration: 1s;
         animation-fill-mode: both;
         animation-iteration-count: 1;
         color: var(--imp-text-color);

         &:hover {
            animation-name: swing;
         }
      }
   }

   .skills-container {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      z-index: 1000;
      padding-left: 10%;
      padding-right: 10%;

      margin: 2rem auto;
      align-items: center;
      gap: 6rem;
      row-gap: 5rem;
      list-style: none;

      @media screen and (max-width: 1024px) {
         justify-content: center;
         gap: 4rem;
      }

      @media screen and (max-width: 768px) {
         width: 100%;
         gap: 2rem;
         justify-content: center;
         padding: 0;
      }

      .skills-item {
         width: 18rem;
         height: 12rem;
         background: none;
         border: 2px solid var(--imp-text-color);
         border-radius: 1rem;
         padding: 1rem 2rem;
         display: flex;
         align-items: center;
         justify-content: center;
         box-shadow: 0.8rem 0.8rem var(--imp-text-color);
         position: relative;
         cursor: pointer;
         text-align: center;

         &:hover {
            // background-color: var(--imp-text-color);
            .skills-icon {
               opacity: 0;
            }
            .skills-name {
               opacity: 1;
            }
         }
      }

      .skills-icon {
         font-size: 8rem;
         display: flex;
         align-items: center;
         justify-content: center;
         transition: opacity 0.2s ease;
      }

      .skills-name {
         font-size: 2.2rem;
         opacity: 0;
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
         transition: opacity 0.2s ease;
      }
   }
}
