.about-page {
   width: 100%;
   height: 100%;
   color: #fff;
   display: block;
   overflow-y: auto;
   padding-bottom: 10rem;

   @media screen and (min-width: 768px) {
      .heading-mobile {
         display: none;
      }
   }

   @media screen and (max-width: 768px) {
      .heading-desktop {
         display: none;
      }

      padding: 0rem;
   }

   .about-page-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media screen and (max-width: 1024px) {
         flex-direction: column;
         padding: 0;
         padding-bottom: 5rem;
      }
   }

   .avater-container {
      width: 50%;
      height: 100%;
      margin: auto;

      @media screen and (max-width: 768px) {
         width: 100%;
         height: auto;
         margin-bottom: 2rem;
      }
   }

   .about-page-text {
      width: 90%;
      height: 100%;
      margin-top: 2rem;
      line-height: 1.7;
      @media screen and (max-width: 1024px) {
         width: 100%;
         height: auto;
         margin-bottom: 2rem;
      }
   }

   .avatar {
      width: 30rem;
      height: 30rem;
      border-radius: 50%;
      margin: 2rem auto;
      margin-bottom: 3rem;
      border: 0.8rem solid var(--imp-text-color);

      @media screen and (max-width: 1024px) {
         width: 25rem;
         height: 25rem;
         margin: 1rem auto;
      }

      @media screen and (max-width: 768px) {
         width: 25rem;
         height: 25rem;
         margin: 0 auto;
      }

      img {
         width: 100%;
         height: 100%;
         border-radius: 50%;

         @media screen and (max-width: 768px) {
            width: 100%;
            height: 100%;
         }
      }
   }

   h1 {
      text-transform: uppercase;
      font-size: 4.5rem;
      width: 100%;
      text-align: center;
      color: #fff;
      margin-bottom: 3rem;
      letter-spacing: 2px;
      margin-top: 2rem;
      display: inline-block;
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-iteration-count: 1;

      span {
         margin-inline: 0.5rem;
         display: inline-block;
         animation-duration: 1s;
         animation-fill-mode: both;
         animation-iteration-count: 1;
         &:hover {
            animation-name: swing;
         }
      }

      @media screen and (max-width: 1024px) {
         font-size: 4rem;
         margin-bottom: 2rem;
      }

      @media screen and (max-width: 768px) {
         font-size: 3.6rem;
         margin-bottom: 2rem;
      }

      span {
         color: var(--imp-text-color);
      }
   }

   p {
      width: 90%;
      font-size: 2rem;
      margin-left: auto;
      margin-bottom: 3rem;
      letter-spacing: 1px;

      span {
         color: var(--imp-text-color);
         margin-inline: 0.5rem;
         display: inline-block;
         animation-duration: 1s;
         animation-fill-mode: both;
         animation-iteration-count: 1;
         &:hover {
            animation-name: rubberBand;
         }
      }

      @media screen and (max-width: 1024px) {
         text-align: center;
         font-size: 1.9rem;
         margin: 3rem auto;
      }

      @media screen and (max-width: 768px) {
         font-size: 1.6rem;
         margin: 2rem auto;

         ::first-line {
            margin-left: 1rem;
         }
      }

      span {
         color: var(--imp-text-color);
      }
   }

   .social-btn-container {
      width: 100%;
      height: 8rem;
      padding-bottom: 2rem;
      text-align: center;
      z-index: 1000;

      @media screen and (min-width: 1024px) {
         display: none;
      }

      ul {
         display: flex;
         justify-content: space-evenly;
         align-items: center;
         list-style: none;
         li {
            font-size: 1.9rem;
            margin-inline: 0.5rem;
            display: inline-block;
            animation-duration: 1s;
            animation-fill-mode: both;
            animation-iteration-count: 1;
            &:hover {
               animation-name: rubberBand;
            }

            a {
               color: var(--imp-text-color);
               text-decoration: none;
               transition: all 0.3s ease-in-out;
               &:hover {
                  color: var(--imp-text-color);
                  transform: scale(1.1);
               }
            }
         }
      }
   }
}
